import MainContainer from "../../components/Result/MainContainer";
import Confetti from 'react-confetti'
import ResultItemContainer from "../../components/Result/ResultItemContainer";
import OptionBtn from "../../components/Result/OptionBtn";
import ShareBtn from "../../components/Result/ShareBtn";
import { useEffect, useState } from "react";
import { useLocation,  } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from "axios";




const SharePage = () => {
    const [itemList, setItemList] = useState([]);
    const { surveyId } = useParams();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const logId = query.get("num");

    useEffect(() => {
        // calibration 버튼 클릭 시 유저 로그 생성
        const getResult = async () => {
            try {
                await axios.get(process.env.REACT_APP_API_URL + 'result?num=' + logId)
                    .then((res) => {
                        setItemList(res.data.data[0].result)
                    });

            } catch (error) {
                console.log(error)
            }
        }
        getResult();
    }, [])

    return (
        <>

            <MainContainer >
                <Confetti
                    width={window.width}
                    height={window.height}
                    opacity={0.5}
                />
                {itemList.length > 0 &&
                    (<>
                        <div className='flex justify-center flex-col mt-10 mb-3 font-yeongdeok  text-omg-main-text'>
                            <p className='text-lg'>
                    
                            </p>
                            <p className='text-sm'>내 무의식이 말해준 나의 취향은 바로 이것! 🥰</p>
                        </div>
                        <ResultItemContainer 
                  item={itemList[0]}
                  main={true}
                  order={1}
                  text={"나의 스타일"}
                />

               
                        <div onClick={() => {  window.location.replace(`/s/${surveyId}`);}} className="flex mx-auto">
                <OptionBtn text={"다시 하러 가기!"}  />
                </div>
    

                        <ShareBtn text={"친구한테 결과 공유하기!"} />
                    </>
                    )
                }
            </MainContainer>

        </>
    )
}

export default SharePage;