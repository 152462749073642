
import { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { playingState, threadsSupportedState, progressState, currentSurveyState, currentLogState, cameraPermissionState } from '../../recoil/atom';
import TestItemContainer from "../../components/Test/TestItemContainer";
import EyeTracker from '../../utils/EyeTracker';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Human from '@vladmandic/human';
import {
    MediaPermissionsError,
    MediaPermissionsErrorType,
    requestMediaPermissions,
  } from 'mic-check';


const config = {
    debug: true,
    backend: 'webgl',
    // cacheSensitivity: 0,
    // cacheModels: false,
    // warmup: 'none',
    // modelBasePath: '../../models',
    modelBasePath: 'https://vladmandic.github.io/human-models/models/',
    filter: { enabled: true, equalization: false, flip: false },
    face: { enabled: true, detector: { rotation: false }, mesh: { enabled: true }, attention: { enabled: false }, iris: { enabled: true }, description: { enabled: true }, emotion: { enabled: true }, antispoof: { enabled: true }, liveness: { enabled: true } },
    body: { enabled: false },
    hand: { enabled: false },
    object: { enabled: false },
    segmentation: { enabled: false },
    gesture: { enabled: true },
  }

  const timestamp = { detect: 0, draw: 0, tensors: 0, start: 0 }; 

const HomePage = ({ calibrationTitleVisible, showFocusText, onCalibrationBtnClickRef, currentFoodsRef, audioRef, progressValue, FIRST_ITEM, SECOND_ITEM, isImageVisible, canvasRef, itemListRef, setShowFocusText, setCalibrationTitleVisible }) => {
    const [info, setInfo] = useState(3);
    const [isPlaying, setIsPlaying] = useRecoilState(playingState);
    const [isThreadSupported, setIsThreadSupported] = useRecoilState(threadsSupportedState);
    const [progress, setProgress] = useRecoilState(progressState);
    const [currentSurvey, setCurrentSurvey] = useRecoilState(currentSurveyState);
    const [currentLog, setCurrentLog] = useRecoilState(currentLogState);
    const [cameraPermission, setCameraPermission] = useRecoilState(cameraPermissionState);
    let localstream;
    const videoRef = useRef(null);
    const cameraRef = useRef(null);

    const human = new Human(config);
    
    useEffect(() => {
        human.load().then(() => {
            console.log('initializing....');
            human.warmup().then(() => {
                console.log('ready...')
            });
        });
        
      
    }, []);

    // 성별, 나이, 감정 상태 데이터 받기 
    
    useEffect(() => {
        /*
        webCam();
        detectionLoop();*/
        
        
    }, [human.result]);

    useEffect(() => {
        checkCameraPermission();
    }, [cameraPermission]);

   
    async function webCam(){
        const width = 1920;
        const devices = await human.webcam.enumerate();
        const id = devices[0].deviceId;
        const webcamStatus = await human.webcam.start({element: videoRef.current, crop: false, width, id});
        console.log(webcamStatus);
        if(human.webcam.paused) await human.webcam.play();
    }

    async function detectionLoop() {
        if(!videoRef.current?.paused){
            if(timestamp.start === 0) timestamp.start = human.now();
            await human.detect(videoRef.current);
            const tensors = human.tf.memory().numTensors;
            timestamp.tensors = tensors;
        }
            timestamp.detect = human.now();
            requestAnimationFrame(detectionLoop);
            if(human.result.face.length > 0) {
            const userAge = human.result?.face[0].age;
            const userEmotion = human.result?.face[0].emotion[0];
            const userGender = human.result?.face[0].gender;
            const userGenderScore = human.result?.face[0].genderScore;
            }
    }

    async function checkCameraPermission() {
        // doesn't work in Safari 

        const cameraPermissionChrome = await navigator.permissions.query({ name: 'camera' });

        if(cameraPermissionChrome.state === "granted") {
            setCameraPermission(true);
        } else {
        await requestMediaPermissions()
        .then(() => {
            // can successfully access camera and microphone streams
            // DO SOMETHING HERE
            setCameraPermission(true);
        })
        .catch((err) => {
            const { type, name, message } = err;
            if (type === MediaPermissionsErrorType.SystemPermissionDenied || type === MediaPermissionsErrorType.UserPermissionDenied) {
                // browser does not have permission to access camera or microphone
                toast.error("카메라 권한이 없으면 무의식을 찾을 수 없어요! 새로고침 후 카메라 권한을 허용해주세요 👀", {
                    position: "bottom-center",
                    autoClose: 10000});
            }else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
                // (mostly Windows specific problem)
                toast.info("카메라가 다른 앱에서 사용 중입니다. 👀", {
                    position: "bottom-center",
                    autoClose: 10000});

            } else {
                // not all error types are handled by this library
                toast.error("카메라 권한이 없으면 무의식을 찾을 수 없어요! 새로고침 후 카메라 권한을 허용해주세요 👀", {
                    position: "bottom-center",
                    autoClose: 10000});
            }
        });
    }
        
    }
    

    const { surveyId } = useParams();

    useEffect(() => {
        const getSurvey = async () => {
            const response = await axios.get(process.env.REACT_APP_API_URL + `survey/${surveyId}`);
            setCurrentSurvey(response.data.data[0]);

        }
        getSurvey();
    }, []);

    // 전체 아이템 리스트 가져오기
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + `items/${surveyId}`)
            .then((res) => {
                res.data.data.forEach((item) => {
                    item.count = 0;
                    item.checked = false;
                })
                itemListRef.current = res.data.data;
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);


    // calibration 버튼 클릭 시 유저 로그 생성
    const createLog = async () => {
        if (surveyId) {
            axios.post(process.env.REACT_APP_API_URL + 'surveylog', {
                surveyId: Number(surveyId),
                finish: false,
                share: false
            }).then((res) => {
                // log data 
                setCurrentLog(res.data.data[0]);

            })
        }
    }

    useEffect(() => {
        if (progress === "afterCalibration" && info !== -2) {
            let interval = setInterval(() => {
                setInfo(info - 1);
            }, 1500);
            if (info === -1) {
                setProgress("testStart");
            }
            return () => clearInterval(interval);
        }
    }, [progress, info]);

    useEffect(() => {
       
        if (currentSurvey?.language == "en") {
            toast.info("Camera permission is required to find the unconscious (eye) 👀", {
                position: "bottom-center",
                autoClose: 5000,
                theme: "colored",
            })
           
        } else {
            toast.info("무의식(눈)을 찾기 위해 카메라 동의가 필요합니다 👀", {
                position: "bottom-center",
                autoClose: 5000,
                theme: "colored",
            })
        }

    }, []);

    


    const handlePlay = () => {

        audioRef.current.play();
    }

    const handleStop = () => {
        audioRef.current.pause();
    }

    return (
   
            Object.keys(currentSurvey).length !== 0 && (
                <>
             <EyeTracker
                canvasRef={canvasRef}
                FIRST_ITEM={FIRST_ITEM}
                SECOND_ITEM={SECOND_ITEM}
                itemListRef={itemListRef}
                currentFoodsRef={currentFoodsRef}
                setCameraPermission={setCameraPermission}
                setShowFocusText={setShowFocusText}
                setCalibrationTitleVisible={setCalibrationTitleVisible}
                onCalibrationBtnClickRef={onCalibrationBtnClickRef}
            />
            <video id="video" ref={videoRef} playsinline className="hidden"></video>
            <ToastContainer />
            <div>

                {((progress === "beforeCalibration") || (progress === "Calibration")) && (currentSurvey?.background_music) && (!isPlaying ? (currentSurvey?.language === "ko" ? (<button onClick={() => { handlePlay(); setIsPlaying(!isPlaying) }} className='absolute bg-omg-yellow p-2 font-yeongdeok rounded-lg text-omg-main-text left-1/2 -translate-x-1/2 top-2 z-[3]'>배경 음악 🎵</button>) : (<button onClick={() => { handlePlay(); setIsPlaying(!isPlaying) }} className='absolute bg-omg-yellow p-2 font-yeongdeok rounded-lg text-omg-main-text left-1/2 -translate-x-1/2 top-2 z-[3]'>Background Music 🎵</button>)) : (currentSurvey?.language === "ko" ? (<button onClick={() => { handleStop(); setIsPlaying(!isPlaying) }} className='absolute bg-omg-yellow p-2 font-yeongdeok rounded-lg text-omg-main-text left-1/2 -translate-x-1/2 top-2 z-[3]'>배경 음악 ❌</button>) : (<button onClick={() => { handleStop(); setIsPlaying(!isPlaying) }} className='absolute bg-omg-yellow p-2 font-yeongdeok rounded-lg text-omg-main-text left-1/2 -translate-x-1/2 top-2 z-[3]'> Background Music ❌</button>)))}

                {progress === "beforeCalibration" && (
                    <>
                        {currentSurvey.background_video && <video crossorigin="anonymous" type="video/mp4" className="flex fixed z-[-2] w-[100vw] left-1/2 -translate-x-1/2 sm:w-1/2 " autoPlay muted loop playsInline>
                            <source src={`${currentSurvey?.background_video}`} type="video/mp4" />
                        </video>}

                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-full mt-[70px] sm:w-1/2'>
                            <div className='bg-omg-yellow w-full h-[290px] bg-primary  -skew-y-12 opacity-80' />
                            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-full'>
                                <div className='text-center flex flex-col mx-auto items-center px-6 pt-4 md:w-2/3 fade-in-bottom delay text-omg-main-text font-yeongdeok'>
                                    {calibrationTitleVisible && <div className='z-10  text-lg ' >{currentSurvey?.name}</div>}
                                    <p className='my-3 text-sm'>{currentSurvey?.description} </p>
                                    {currentSurvey?.language === "ko" ? (<p className='text-[12px] my-1 text-[black]'>현재 총 {currentSurvey?.visitors}명이 참여했습니다.</p>) : (<p className='text-[12px] my-1 text-[black]'>Currently {currentSurvey?.visitors} total played.</p>)}
                                    {(cameraPermission && isThreadSupported) ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-16 h-16 cursor-pointer text-omg-main-text" onClick={async () => { await createLog(); await onCalibrationBtnClickRef.current(); }}><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clip-rule="evenodd"></path></svg>) : (
                                        <div role="status" className='mt-2'>
                                            <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-[gray] fill-[yellow]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            {currentSurvey?.language === "ko" ? (<span className="flex mt-2 font-yeongdeok text-lg text-omg-main-text" >로딩 중...</span>) : (<span className="flex mt-2 font-yeongdeok text-lg text-omg-main-text" >Loading...</span>)}
                                        </div>
                                    )
                                    }
                                    {!isThreadSupported && <p className='text-[12px] my-1 font-yeongdeok text-lg text-omg-main-text' >현재 브라우저에서는 지원하지 않습니다. 다른 브라우저에서 시도해주세요! 🤖</p>}

                                </div>

                            </div>

                        </div>
                    </>
                )}
                {progress === "Calibration" && <div className='bg-calibration-bg w-full h-full absolute z-[-2]'>
                    {(showFocusText) && (currentSurvey?.language === "ko" ? (<div className='absolute text-center transform top-1/2 left-1/2 -translate-x-1/2 z-10 text-omg-main-text font-yeongdeok'>더 정교한 분석을 위해<br /> 아래 그림을 바라봐주세요! 😊</div>) : (<div className='absolute text-center transform top-1/2 left-1/2 -translate-x-1/2 z-10 text-omg-main-text font-yeongdeok'>For a more sophisticated analysis<br /> look at the figure below! 😊</div>))}
                    {(!showFocusText) && (currentSurvey?.language === "ko" ? (<div className='absolute text-center transform top-1/4 left-1/2 -translate-x-1/2 z-10 text-calibration-text font-yeongdeok'>그림을 바라보면 <br />미세조정이 됩니다...!</div>) : (<div className='absolute text-center transform top-1/4 left-1/2 -translate-x-1/2 z-10 text-calibration-text font-yeongdeok'>By looking at the picture <br />fine-tuning is done...!</div>))}
                  
                </div>
                }

                {progress === "afterCalibration" && <div className='absolute w-full h-full bg-calibration-bg font-yeongdeok left-1/2 -translate-x-1/2 sm:w-1/2'>
                    <p className='top-1/3 text-[white] mt-10 ml-5 text-lg'>{currentSurvey?.guide_info}</p>
                    <img src="/image/eye-icon.png" alt="" className='w-[100px] h-[100px] top-2/3 ml-5 mt-10' />
                    <div className='bg-omg-yellow w-full h-1/3 top-[40%] bg-primary  -skew-y-12'>

                    </div>
                    <div className='text-right mr-5'>
                        <p className='text-8xl text-[white] relative font-yeongdeok'>{info}</p>
                    </div>
                </div>}

                {(progress === "testStart" && currentFoodsRef.current.length !== 0) && (
                    <>
                        <div className="relative w-full bg-[gray] h-2.5 z-2">
                            <div style={{ width: `${progressValue}%` }} className="absolute bg-omg-yellow h-2.5 z-3 "></div>
                        </div>

                        <div className='flex flex-col sm:flex-row bg-omg-yellow'>
                            <TestItemContainer
                                isImageVisible={isImageVisible}
                                itemRef={FIRST_ITEM}
                                currentItem={currentFoodsRef.current[0]}
                                main={true}
                            />
                            <img src="/image/vs.webp" alt="" className='absolute z-[44] w-[50px] h-[50px] transform top-[43vh] left-1/2 -translate-x-1/2 sm:w-[10vw] sm:h-[10vh] sm:top-1/2'/>
                            <TestItemContainer
                                isImageVisible={isImageVisible}
                                itemRef={SECOND_ITEM}
                                currentItem={currentFoodsRef.current[1]}
                                main={false}
                            />

                        </div>
                    </>
                )

                }

            </div>
            </>
            )
      
    )
}

export default HomePage;