

const TestItemContainer = ({ isImageVisible, itemRef, currentItem, main }) => {
    return (
        <>
            <div className='relative w-[100vw] h-[45vh] md:w-[50vw] md:h-[100vh] mx-[auto]' >
                <img crossorigin="anonymous" className={`object-cover h-full w-full  ${isImageVisible ? 'opacity-100' : 'opacity-80'
                    } transition-opacity`} ref={itemRef} src={currentItem?.image} alt={currentItem?.name} />
                <div className={`absolute ${main ? ("top-2") : ("bottom-5")} flex items-center opacity-70 right-4 text-[white]`}><div className='font-yeongdeok'>{currentItem?.count}</div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 ml-2 "><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"></path><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg></div>
            </div>
        </>
    )
}

export default TestItemContainer;