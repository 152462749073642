import React, { useEffect, useState, useRef, useCallback } from 'react';
import ImagePreLoad from './utils/ImagePreLoad';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import ResultPage from './pages/ResultPage/ResultPage';
import {
    useRecoilValue,
    useRecoilState,
} from 'recoil';
import { playingState, progressState, surveyListState, currentSurveyState, currentLogState } from './recoil/atom';
import axios from 'axios';
import SharePage from './pages/SharePage/SharePage';
import Footer from './components/Footer/Footer';


let Time_Per_Image = 4; // seconds, the time for each image to be shown
let Number_Of_Image_Set = 5; // The number of image sets to be shown


const Index = () => {

    const currentPlaying = useRecoilValue(playingState);
    const [isImageVisible, setIsImageVisible] = useState(true);

    const [calibrationTitleVisible, setCalibrationTitleVisible] = useState(true);
    const [showFocusText, setShowFocusText] = useState(false);


    const [progressValue, setProgressValue] = useState(0);
    const [cameraPermission, setCameraPermission] = useState(false);

    const [progress, setProgress] = useRecoilState(progressState);
    const [surveyList, setSurveyList] = useRecoilState(surveyListState);
    const [currentSurvey, setCurrentSurvey] = useRecoilState(currentSurveyState);
    const [currentLog, setCurrentLog] = useRecoilState(currentLogState);


    const canvasRef = useRef(null);
    const FIRST_ITEM = useRef(null);
    const SECOND_ITEM = useRef(null);
    const onCalibrationBtnClickRef = useRef(null);
    const currentFoodsRef = useRef([]);
    const itemListRef = useRef([]);
    const audioRef = useRef(null);
    const wholeRef = useRef(null);



    useEffect(() => {
        if (progress === "testStart") {
            currentFoodsRef.current = selectFoods();
        }
    }, [progress]);


    // 전체 서베이 리스트 가져오기
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'surveys')
            .then((res) => {
                setSurveyList(res.data.data);
    
                
            })
            .catch((err) => {
                console.log(err);
            })
    }, [surveyList.length]);
   




    // progress bar 24초간 지속 , 8세트 , 한 세트당 3초  (예제 5세트, 15초)
    useEffect(() => {
        if (progress === "testStart" && progressValue < 100) {
            let interval = setInterval(() => {
                setProgressValue(progressValue + (0.1));
            }, (Time_Per_Image * Number_Of_Image_Set));
            return () => clearInterval(interval);
        }
    }, [progress, progressValue]);


    useEffect(() => {
        if (progress === "testStart") {
            let round = 0;

            const interval = setInterval(() => {
                if (round === Number_Of_Image_Set - 1) {
                    clearInterval(interval);
                    setProgress("testEnd")
                    itemListRef.current = sortItemList();

              
                } else {
                    currentFoodsRef.current = selectFoods();
                    setIsImageVisible(false); // Start the fade-out animation before changing the image
                    setTimeout(() => {
                        setIsImageVisible(true); // Start the fade-in animation after changing the image
                    }, 500); // Change this value to control the duration of fade-out and fade-in
                    round++;
                }
            }, Time_Per_Image * 1000);

            return () => clearInterval(interval);
        }
    }, [progress]);


    function selectFoods() {
        const notShownItems = itemListRef.current.filter((item) => !item.checked);
        if (notShownItems.length < 2) {
            itemListRef.current =  itemListRef.current.map((item) => ({ ...item, checked: false })); // Reset all foods' checked status
            return [];
        }
        const selectedItems = [];
        const selectedIndices = new Set();
        while (selectedItems.length < 2) {
            const idx = Math.floor(Math.random() * notShownItems.length);
            if (!selectedIndices.has(idx)) {
                selectedIndices.add(idx);
                selectedItems.push(notShownItems[idx]);
                notShownItems[idx].checked = true; // Mark the food as checked
            }
        }
        return selectedItems;
    }


    // sort itemList by count
    const sortItemList = () => {
        let sortedItemList = [...itemListRef.current];
        sortedItemList.sort((a, b) => {
            return b.count - a.count;
        });
        return sortedItemList;
    }
   
    
        



    const Home = () => {
        return (
            <>
                { surveyList.length > 0 && ( 
                    <>
                    <div className='justify-center flex p-5'>
                     <img crossorigin="anonymous" src="/image/ieye.webp" className='w-[100vw] h-[40vh] sm:w-[50vw]' alt=""/>
                     </div>
                    <div className='flex items-center  flex-col space-y-5'>
                       
                    {surveyList.map((survey, index) => (
                        <div className='border border-omg-yellow bg-omg-yellow flex flex-row z-[99] items-center w-[100vw] sm:w-[50vw]'>
                         <img crossorigin="anonymous" src={survey?.thumbnail} alt="logo" className='w-16 h-16 ' />
                        <Link to={`/s/${survey?.id}`} className='flex flex-col bg-omg-yellow/90 p-4 hover:bg-omg-yellow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-omg-yellow' key={index}
                        onClick={() => {setCurrentSurvey(survey)}}
                        >
                        <div className=' font-yeongdeok '>{survey?.name}</div>
                        <div className=' font-bold'>{survey?.description}</div>
                        </Link>
                        </div>

                    ))}
                    </div>
                    <Footer/>
                    </>
                )}
            </>
        )
    }
    return (
        <>
        <div ref={wholeRef}>
            <ImagePreLoad
                itemList={itemListRef.current}
            />
            <canvas ref={canvasRef} className='fixed z-[2] '> </canvas>
            <div className='fixed h-full w-full bg-[black] z-[-4]'></div>
            {currentSurvey.background_music && <audio crossorigin="anonymous" ref={audioRef} >
                <source  src={`${currentSurvey?.background_music}`} type="audio/mp3" loop={true}/>
            </audio>}
            <Router>
                <Routes>
                    <Route exact path="/"
                        element={
                            <Home />
                        }
                    />
                    <Route exact path="/s/:surveyId"
                        element={(progress === "testEnd") ? (<Navigate replace to={`/s/${currentSurvey?.id}/result?num=${currentLog?.id}`} />) : (
                            <HomePage
                            currentPlaying={currentPlaying}
                                itemListRef={itemListRef}
                                audioRef={audioRef}
                                calibrationTitleVisible={calibrationTitleVisible}
                                cameraPermission={cameraPermission}
                                showFocusText={showFocusText}
                                onCalibrationBtnClickRef={onCalibrationBtnClickRef}
                                isImageVisible={isImageVisible}
                                currentFoodsRef={currentFoodsRef}
                                FIRST_ITEM={FIRST_ITEM}
                                SECOND_ITEM={SECOND_ITEM}
                                progressValue={progressValue}
                                canvasRef={canvasRef}
                                setCameraPermission={setCameraPermission}
                                setShowFocusText={setShowFocusText}
                                setCalibrationTitleVisible={setCalibrationTitleVisible}

                            />)
                        }
                    />
                    <Route exact path="/s/:surveyId/result"
                        element={
                            <ResultPage
                                itemList={itemListRef.current}
                                itemListRef={itemListRef}
                            />
                        }
                    />
                    <Route exact path="/s/:surveyId/share"
                        element={
                            <SharePage
                            />
                        }
                    />
                </Routes>
            </Router>
        </div>
        </>
    );

};

export default Index;
