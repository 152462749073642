import MainContainer from "../../components/Result/MainContainer";
import Confetti from 'react-confetti'
import ResultItemContainer from "../../components/Result/ResultItemContainer";
import OptionBtn from "../../components/Result/OptionBtn";
import ShareBtn from "../../components/Result/ShareBtn";
import { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { currentLogState, currentSurveyState, progressState } from "../../recoil/atom";
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from "axios";



const ResultPage = ({itemList, itemListRef}) => {
    
    const [currentLog, setCurrentLog] = useRecoilState(currentLogState);
    const [currentSurvey, setCurrentSurvey] = useRecoilState(currentSurveyState);
    const { surveyId } = useParams();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const logId = query.get("num");

    useEffect(() => {
            // calibration 버튼 클릭 시 유저 로그 생성
    const createResult = async () => {
        try {
            if(!currentLog) return;
            if(itemList.length === 0) return;
            await axios.post(process.env.REACT_APP_API_URL+ 'result', {
                log_id: currentLog.id,
                result: itemList 
            })
        } catch (error) {
            console.log(error)
        }
    }
    createResult();
    updateFinishLog();
    }, [])

        // calibration 버튼 클릭 시 유저 로그 생성
        const updateFinishLog = async () => {
          if (currentLog.id) {
              axios.put(process.env.REACT_APP_API_URL + 'surveylog', {
                  logId: Number(currentLog.id),
                  finish: true,
                  share: false
              })
          }
      }

      const updateShareLog = async () => {
        if (currentLog.id) {
            axios.put(process.env.REACT_APP_API_URL + 'surveylog', {
                logId: Number(currentLog.id),
                finish: true,
                share: true
            })
        }
    }

    useEffect(() => {
        const getResult = async () => {
            try {
              if(itemList.length > 0) return;
                await axios.get(process.env.REACT_APP_API_URL + 'result?num=' + logId) 
                .then((res) => {
                  itemListRef.current = res.data.data[0].result;
                });
            } catch (error) {
                console.log(error);

            }
            }
            getResult();
    }, []);




    return (
         <>
       <MainContainer >
          {itemList.length > 0 && (
            <>
            <Confetti
              width={window.width}
              height={window.height}
              opacity={0.5}
            />
                
                <ResultItemContainer 
                  item={itemList[0]}
                  main={true}
                  order={1}
                  text={"나의 스타일"}
                />
                <div className="flex flex-col justify-center items-center p-4 rounded-xl w-full mt-2 shadow-omg bg-omg-yellow/80">

                 <p className='text-sm font-yeongdeok  text-[black]'>내 무의식이 말해준 나의 취향은 바로 이것! 🥰</p>
                 <p className="mt-3 font-yeongdeok text-lg text-[#3333ea]">XX 타입</p>
                  <div className="w-5 h-1 bg-omg-main-text my-3"></div>
                <div className="font-yeongdeok text-sm flex flex-col items-center">
                  

                </div>
                </div>


                { currentSurvey?.cta_text && currentSurvey?.cta_link && (
                <Link to={currentSurvey?.cta_link} className="flex mx-auto" >
                <OptionBtn text={currentSurvey?.cta_text} />
                </Link>
                )
                }


                <div onClick={() => {  window.location.replace(`/s/${surveyId}`);}} className="flex mx-auto">
                <OptionBtn text={"다시 하러 가기!"}  />
                </div>
                <div className="bottom-5  mx-auto sticky" onClick={updateShareLog}>
                <ShareBtn text={"친구한테 결과 공유하기!"}/>
                </div>
            </>
              )}
            </MainContainer>


        </>
    )
}

export default ResultPage;