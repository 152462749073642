

const MainContainer = ({ children }) => {
    return (
        <>
            <div className='absolute left-1/2 transform -translate-x-1/2 w-full sm:w-1/2 z-[10]'>
                <div className='flex flex-col w-full min-h-[100vh] bg-omg-yellow items-start px-2 py-5 text-start sm:w-[50vw]'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default MainContainer;