

const ResultItemContainer = ({item, main, order, text} ) => {
    return (
        <>
                <div className='relative aspect-16/7 '>
                  <div className="absolute w-8 h-8 bg-calibration-bg text-[white] flex justify-center items-center font-yeongdeok" style={{ animationDelay: "2000ms" }}>{order}.</div>
                  <img crossorigin="anonymous" src={`${item?.final_image ? item?.final_image : item?.image }`} alt={`${item?.image}`} className={`bg-center rounded-2xl ${ main ? ("w-[100vw] sm:w-[100vw] sm:h-[40vh]"): ("w-[50vw] h-[50%] sm:w-[25vw] sm:h-[50vh]")}}`} style={{ animationDelay: "2000ms" }} />
                  <span className='opacity-90 absolute bottom-5 px-4 py-1 text-center text-sm text-[#5d5d5d] font-yeongdeok  left-1/2 transform -translate-x-1/2 bg-calibration-text rounded-xl' style={{ animationDelay: "3000ms" }}>
                    {item?.name}
                  </span>
                </div>
        </>
    )
} 

export default ResultItemContainer;