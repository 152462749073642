import  { useEffect } from 'react';

const ItemImagePreLoad = ({ itemList }) => {
    useEffect(() => {
        itemList.forEach((item) => {
            const img = new Image();
            img.src = item.image;

            const gif = new Image();
            gif.src = item.final_image;

        }
        );
    }, []);
   
    return null;
};

export default ItemImagePreLoad;