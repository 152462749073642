import React, { useState, useEffect, useRef } from 'react';
import { set } from 'react-ga';
import EasySeeso from 'seeso/easy-seeso';
import * as check from 'wasm-check';
import { useRecoilState } from 'recoil';
import { threadsSupportedState, progressState, currentSurveyState } from '../recoil/atom'

let eyeTracker = null;

const EyeTracker = ({ canvasRef, FIRST_ITEM, SECOND_ITEM, itemListRef, currentFoodsRef, setCameraPermission, setShowFocusText, setCalibrationTitleVisible, onCalibrationBtnClickRef }) => {
  const licenseKey = process.env.REACT_APP_LICENSE_KEY;
  const [isThreadSupported, setIsThreadSupported] = useRecoilState(threadsSupportedState);
  const [currentSurvey, setCurrentSurvey] = useRecoilState(currentSurveyState);
  const [progress, setProgress] = useRecoilState(progressState);

  const IS_GAZE_IMAGE = true;
  const DOT_MAX_SIZE = 10;
  const DOT_MIN_SIZE = 5;
  let x, y;

  useEffect(() => {
    if (check.feature.threads) {
      setIsThreadSupported(true);
      console.log("threads supported")
    }
  }, []);

  useEffect(() => {
    const initializeEyeTracker = async () => {
      if (!eyeTracker) {
        eyeTracker = new EasySeeso();
        await eyeTracker.init(
          licenseKey,
          () => {
            eyeTracker.startTracking(onGaze);
            eyeTracker.setMonitorSize(14);
            eyeTracker.setFaceDistance(50);
            eyeTracker.setCameraPosition(window.outerWidth / 2, true);

            console.log("Init success");

          },// callback when init succeeded.
          () => console.log("callback when init failed"),  // callback when init failed.
        );

      }
    };
    initializeEyeTracker();
  }, []);


  // gaze callback.
  function onGaze(gazeInfo) {
    if (progress !== "Calibration" && progress !== "afterCalibration") {
      const { x, y } = gazeInfo;
      showGazeDotOnDom(gazeInfo);


      // Check if the gaze is on the food images
      [FIRST_ITEM, SECOND_ITEM].forEach((foodRef, index) => {
        if (foodRef.current) {
          const rect = foodRef.current.getBoundingClientRect();
          if (
            x >= rect.left &&
            x <= rect.left + rect.width &&
            y >= rect.top &&
            y <= rect.top + rect.height
          ) {
            let countedItem = [];
            itemListRef.current.map(item => {
              if (item.name === currentFoodsRef.current[index]?.name) {


                currentFoodsRef.current[index].count = currentFoodsRef.current[index].count + 10;
                countedItem.push({ ...item, count: item.count + 10 });
              } else {
                countedItem.push(item);
              }
            });
            itemListRef.current = (countedItem);
          }
        }
      });
    } else {
      hideGaze();
    }
  }

  // hide gaze Dot on screen.
  function hideGaze() {
    let canvas = canvasRef.current;
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  // helper functions to display gaze information and dot in browser.
  function showGazeDotOnDom(gazeInfo) {
    let canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let ctx = canvas.getContext("2d");
    if (IS_GAZE_IMAGE) {
      let img = new Image();
      img.onload = function () {
        ctx.drawImage(img, gazeInfo.x, gazeInfo.y, 20, 20);
      };
      img.crossOrigin = "anonymous";
      img.src = currentSurvey?.gaze_icon;
    } else {

      ctx.fillStyle = '#FF0000'
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.beginPath();
      ctx.arc(gazeInfo.x, gazeInfo.y, 10, 0, Math.PI * 2, true);
      ctx.fill();

    }
  }

  onCalibrationBtnClickRef.current = () => {
    if (progress === "beforeCalibration") {
      console.log("Clibration Start")
      setProgress("Calibration");

      setShowFocusText(true);
      hideGaze();
      setTimeout(() => {
        setShowFocusText(false);

        eyeTracker.startCalibration(onCalibrationNextPoint, onCalibrationProgress, onCalibrationFinished, 5);

      }, 2000);

      setCalibrationTitleVisible(false);
    }
  };

  function onCalibrationProgress(progress) {
    let ctx = clearCanvas();

    let dotSize = DOT_MIN_SIZE + (DOT_MAX_SIZE - DOT_MIN_SIZE) * progress;

    drawCircle(x - 10, y - 10, dotSize, ctx)
  }

  const onCalibrationNextPoint = (pointX, pointY) => {
    x = pointX;
    y = pointY;
    let ctx = clearCanvas();
    drawCircle(x - 10, y - 10, DOT_MIN_SIZE, ctx);
    eyeTracker.startCollectSamples();
  };

  const onCalibrationFinished = (calibrationData) => {
    // calibrationData
    // {"vector":"nJ0lPwncEj7UhMs9bYMpvO2nCz6dobK9L2JcQfzEfUE=","vectorLength":32,"isCameraOnTop":true,"cameraX":720,"monitorInch":14,"faceDistance":50}
    clearCanvas();
    setProgress("afterCalibration");
    setCalibrationTitleVisible(true);
  };

  const clearCanvas = () => {
    let canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    return ctx;
  };

  const drawCircle = (x, y, dotSize, ctx) => {
    let img = new Image();
    img.onload = function () {
      ctx.drawImage(img, x, y, dotSize * 3, dotSize * 3);
    };
    img.crossOrigin = "anonymous";

    img.src = currentSurvey?.calibration_icon;
  };


};

export default EyeTracker;