

const Footer = () => {
    return (
        <>
            <div className='flex flex-col items-center justify-center mx-auto mt-5 py-2 space-y-1 bg-omg-yellow w-[100vw] sm:w-[50vw]'>
                
                <p className='font-yeongdeok text-omg-main-text text-sm'>IEYE</p>
                <p className='font-yeongdeok text-omg-main-text text-sm'>E-mail: official@ieye.watch</p>
                <a href="https://woojulike.notion.site/a84531cd6a374ee5a47094d29bfc1ab8" className="font-yeongdeok text-omg-main-text text-sm" target="_blank">개인정보처리방침</a>
                <p className='font-yeongdeok text-omg-main-text text-sm'>© 2023 IEYE All rights reserved.</p>
            </div>
        </>
    )
}

export default Footer;